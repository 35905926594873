

const aiModule = angular.module(__filename, [
]);

aiModule.controller('AiController', ['$scope', function($scope) {
}]).stateConfig = {
    name: "ai",
    url: "/ai",
    template: require("./ai.html"),
    controller: 'AiController',
    display: "AI",
    data: {
        permissions: ['ai']
    },
};


module.exports = angular.module(__filename);